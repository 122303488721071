.app-wrapper {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.logo {
    margin-top: -60px;
    margin-bottom: -60px;
}

.app-container {
    background-color: #161616;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
}

.install-area {
    display: flex;
    align-items: center;
    margin-right: -16px;
}

.app-link {
    position: relative;
    display: block;
    margin-bottom: 16px;
    flex: 1 1 200px;
    flex-wrap: wrap;
    margin-right: 16px;
}

.app-link img {
    position: relative;
    display: block;
    height: 45px;
}
